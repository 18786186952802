.awssld__controls__arrow-left:before,
.awssld__controls__arrow-left:after,
.awssld__controls__arrow-right:before,
.awssld__controls__arrow-right:after {
    background-color: white;
    opacity: 1;
}

.awssld__controls__arrow-right,
.awssld__controls__arrow-left {
    opacity: 1 !important;
}
